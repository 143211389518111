import { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useNotification } from 'lib/context/notification.context'
import ModalLoader from 'views/components/UI/ModalLoader/modal-loader'

import { MainLayout } from 'views/layouts'
import FormCreateNewCompany from 'views/components/Forms/companies/createNewCompany'
//services
import createNewCompany from 'services/companies/newCompany'
// routes
import { superAdminRoutes } from 'router/routes'
import getIntegrations, { IIntegration } from 'services/companies/getIntegrations/getIntegrations'

// INTERFACES
type ICompany = {
  name: string
  typeCompanyId: number
  operativeId: number
  contactName: string
  contactEmail: string
  phone: string
  logo?: Blob
  hasIntegration: string
}

const NewCompany: FC = () => {
  const { getError, getSuccess } = useNotification()
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)
  const [integrations, setIntegrations] = useState<IIntegration[]>([])

  useEffect(() => {
    getIntegrations().then((res) => {
      if (res.status === 'ok') {
        setIntegrations(res.data)
      } else {
        console.error('error on get integrations')
        if (!res.status) getError('Error al obtener las integraciones')
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleCreateNewCompany = (values: ICompany) => {
    if (loading) return
    setLoading(true)

    createNewCompany(values).then((res) => {
      if (res.status === 'OK' || res.status === 'success') {
        setLoading(false)
        getSuccess('Compañía creada correctamente')
        navigate(superAdminRoutes.Companies)
      } else {
        console.error('error on create company', res.message)
        if (!res.ok) getError('Error al crear la compañía')
        setLoading(false)
      }
    })
  }

  return (
    <MainLayout>
      {!loading ? (
        <>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginBottom: 35,
              marginTop: 100,
              alignItems: 'baseline',
            }}
          >
            <h2 style={{ fontSize: 32 }}>Nueva compañía</h2>
          </div>
          <FormCreateNewCompany
            integrations={integrations}
            onSubmit={(e: ICompany) => {
              handleCreateNewCompany(e)
            }}
          />
        </>
      ) : (
        <ModalLoader isLoading={loading} />
      )}
    </MainLayout>
  )
}

export default NewCompany
