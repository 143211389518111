import * as Yup from 'yup'

export const SignUpSchema = Yup.object().shape({
  // step 1
  password: Yup.string()
    .min(8, 'Contraseña muy corta')
    .max(50, 'Contraseña demasiado larga')
    .required('La contraseña es requerida')
    .matches(/^(?=.*[A-Z]).+$/, 'La contraseña debe contener al menos una mayúscula')
    .matches(/^(?=.*[a-z]).+$/, 'La contraseña debe contener al menos una minúscula')
    .matches(/[!#$%&*\-/?_~]/, 'La contraseña debe contener al menos un carácter especial'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Las contraseñas ingresadas no coinciden')
    .min(8, 'Muy corta')
    .max(50, 'Contraseña demasiado larga')
    .required('Este campo es requerido'),
  // step 2: validación condicional
  documentType: Yup.string().when('validateDocument', {
    is: true,
    then: Yup.string().required('Este campo es requerido'),
  }),
  documentNumber: Yup.string().when('validateDocument', {
    is: true,
    then: Yup.string()
      .required('Este campo es requerido')
      .min(6, 'Muy corto')
      .max(50, 'Demasiado largo')
      .test('document-format', 'Formato de documento incorrecto', function (value) {
        if (typeof value !== 'string') {
          return false
        }
        const documentType = this.parent.documentType
        if (documentType === '3') {
          return /^[a-zA-Z0-9]+$/.test(value)
        } else {
          if (!/^\d+$/.test(value)) {
            return this.createError({ message: 'El documento debe contener solo números' })
          }
          return true
        }
      }),
  }),
  // step 3
  firstName: Yup.string().min(2, 'Muy corto!').max(50, 'Muy largo!').required('Required'),
  lastName: Yup.string().min(2, 'Muy corto!').max(50, 'Muy largo!').required('Required'),
  birthDate: Yup.string().required('Este campo es requerido'),
})

export interface IItemValidate {
  text: string
  validate: boolean
  token?: string
}

export type ISubmit = {
  password: string
  confirmPassword: string
  documentType: string
  documentNumber: string
  firstName: string
  lastName: string
  birthDate: string
}

export type IDefaultValues = {
  firstName: string
  lastName: string
  role: string
}

export interface IProps {
  onSubmit: (values: ISubmit) => void
  defaultValues?: IDefaultValues
  personId: number
  token: string
}
