import {
  VALIDATE_LOWERCASE_REGEX,
  VALIDATE_SPECIAL_CHARACTERS_REGEX,
  VALIDATE_UPPERCASE_REGEX,
} from 'constants/regex.constants'
import { Form, Formik } from 'formik'
import { useNotification } from 'lib/context/notification.context'
import React, { useCallback, useEffect, useState } from 'react'
import validateDocument from 'services/common/users/validate-document/validateDocument'
import { v4 as uuidv4 } from 'uuid'
import AlertComponent from 'views/components/Alert'
import { Button } from 'views/components/UI/Buttons'
import { Block } from 'views/components/UI/Contents'
import DatePicker from 'views/components/UI/Datepicker'
import Select from 'views/components/UI/Select'
import { P } from 'views/components/UI/Text'
import Input from 'views/components/UI/TextInput'

import { Check } from '@mui/icons-material'

import { IItemValidate, IProps, ISubmit, SignUpSchema } from './constants'
import {
  Container,
  ErrorContainer,
  HaveAccount,
  ItemValidation,
  Row,
  styleInput,
  TextError,
  ValidationsContainer,
} from './styled'
import { useTranslation } from 'react-i18next'

const ItemValidate = (props: IItemValidate) => {
  const { text, validate } = props

  return (
    <ItemValidation>
      <Check sx={{ color: validate ? '#60CC1E' : '#797A7C' }} />
      <P margin="0px 6px" color={validate ? '#60CC1E' : '#797A7C'}>
        {text}
      </P>
    </ItemValidation>
  )
}

const RegisterForm = (props: IProps) => {
  const { t } = useTranslation()
  const optionsDocumentType = [
    { value: 1, label: t('global.common.documentTypes.dni') },
    { value: 2, label: t('global.common.documentTypes.immigrationDocument') },
    { value: 3, label: t('global.common.documentTypes.passport') },
    { value: 4, label: t('global.common.documentTypes.identityCard') },
  ]

  const { onSubmit, defaultValues, personId, token } = props

  const { getWarning } = useNotification()

  const [step, setStep] = useState(0)
  const [showAlertDate, setShowAlerDate] = useState(false)
  const [documentValid, setDocumentValid] = useState(false)
  const [dateValid, setDateValid] = useState(false)

  const [validations, setValidations] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    specialChar: false,
  })
  const [validPassword, setValidPassword] = useState(false)
  const [timerId, setTimerId] = useState<number | null>(null)
  const isAdminUser = ['platform_admin', 'super_admin', 'super_platform_admin'].includes(defaultValues?.role ?? '')
  const handleValidateDocumentNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (timerId) clearTimeout(timerId)

    const document = event.target.value

    const newTimerId = window.setTimeout(() => {
      validateDocument({ document, personId, token }).then((response) => {
        if (response && 'status' in response && response.status === 200) {
          setDocumentValid(true)
        }
        if (response && 'status' in response && response.status === 220) {
          getWarning(t('global.notifications.documentAlreadyRegistered'))
          setDocumentValid(false)
        }
        if (response && 'status' in response && response.status === 221) {
          getWarning(t('global.notifications.userAlreadyActive'))
          setDocumentValid(false)
        }
      })
    }, 500)
    setTimerId(newTimerId)
  }

  useEffect(() => {
    return () => {
      if (timerId) clearTimeout(timerId)
    }
  }, [timerId])

  useEffect(() => {
    const allValid = Object.values(validations).every((value) => value)
    setValidPassword(allValid)
  }, [validations])

  const updateValidations = (password: string) => {
    setValidations({
      length: password.length >= 8,
      uppercase: VALIDATE_UPPERCASE_REGEX.test(password),
      lowercase: VALIDATE_LOWERCASE_REGEX.test(password),
      specialChar: VALIDATE_SPECIAL_CHARACTERS_REGEX.test(password),
    })
  }

  const handleActivate = (values: ISubmit): void => {
    const documentNumber = isAdminUser ? uuidv4() : values.documentNumber
    const documentTypeId = isAdminUser ? 1 : values.documentType

    const data = {
      document: documentNumber,
      documentType: documentTypeId,
      birthDate: new Date(values.birthDate),
      password: values.password,
    }
    onSubmit(data as any)
  }

  const showDateAlert = useCallback(() => {
    setShowAlerDate(true)
    setTimeout(() => {
      setShowAlerDate(false)
    }, 6000)
  }, [])

  const validateDate = (date: string) => {
    const isValid = date !== 'Invalid Date'

    if (isValid !== dateValid) {
      setDateValid(isValid)
    }
  }

  return (
    <Container>
      <Formik
        validateOnChange={true}
        validationSchema={SignUpSchema}
        onSubmit={(values) => {
          handleActivate(values)
        }}
        initialValues={{
          validateDocument: !isAdminUser,
          // step 1
          password: '',
          confirmPassword: '',
          // step 2
          documentType: '',
          documentNumber: '',
          // step 3
          firstName: defaultValues?.firstName || '',
          lastName: defaultValues?.lastName || '',
          birthDate: '',
        }}
      >
        {({ errors, touched, values, handleChange, handleBlur, handleSubmit }) => (
          <Form style={{ width: '80%' }}>
            <Block display="flex" flexDirection="column">
              {step === 0 && (
                <>
                  <Block m="0.5rem">
                    <Input
                      style={styleInput}
                      value={values.password}
                      onChange={(e) => {
                        handleChange('password')(e)
                        updateValidations(e.target.value)
                      }}
                      onBlur={(e) => {
                        handleBlur('password')(e)
                      }}
                      type="text"
                      name="password"
                      label={t('global.register.createYourPasswordPlaceholder')}
                      error={errors.password && touched.password ? true : false}
                    />
                    {errors.password && touched.password ? (
                      <ErrorContainer>
                        <TextError>{errors.password}</TextError>
                      </ErrorContainer>
                    ) : (
                      ''
                    )}
                    {errors && (
                      <>
                        <ValidationsContainer>
                          <ItemValidate
                            text={t('global.register.oneMay')}
                            validate={
                              // validate if password has a uppercase letter
                              values.password.match(/[A-Z]/) ? true : false
                            }
                          />
                        </ValidationsContainer>

                        <ValidationsContainer>
                          <ItemValidate
                            text={t('global.register.oneMin')}
                            validate={
                              // validate if password has a lowercase letter
                              values.password.match(/[a-z]/) ? true : false
                            }
                          />
                        </ValidationsContainer>

                        <ValidationsContainer>
                          <ItemValidate
                            text={t('global.register.oneSpecial')}
                            validate={
                              // validate if password has a special character
                              values.password.match(/[!#$%&*\-/?_~]/) ? true : false
                            }
                          />
                        </ValidationsContainer>

                        <ValidationsContainer>
                          <ItemValidate
                            text={t('global.register.minimumI8Characters')}
                            validate={
                              // validate if password has a minimum of 8 characters
                              values.password.length >= 8 ? true : false
                            }
                          />
                        </ValidationsContainer>
                      </>
                    )}
                  </Block>
                  <Block m="0.5rem">
                    <Input
                      style={styleInput}
                      value={values.confirmPassword}
                      onChange={(e) => {
                        handleChange('confirmPassword')(e)
                      }}
                      onBlur={(e) => {
                        handleBlur('confirmPassword')(e)
                      }}
                      type="text"
                      name="confirmPassword"
                      label={t('global.register.confirmationPasswordPlaceholder')}
                      error={errors.confirmPassword && touched.confirmPassword ? false : true}
                    />
                    {errors.confirmPassword && touched.confirmPassword ? (
                      <ErrorContainer>
                        <TextError>{errors.confirmPassword}</TextError>
                      </ErrorContainer>
                    ) : (
                      ''
                    )}
                  </Block>
                </>
              )}

              {step === 1 && !isAdminUser && (
                <>
                  <Block m="0.5rem">
                    <Select
                      label={t('global.register.documentType')}
                      hideNeverChoice={true}
                      options={optionsDocumentType}
                      value={values.documentType}
                      onChange={(e) => {
                        const value = e.toString()
                        handleChange('documentType')(value)
                      }}
                    />
                    {errors.documentType ? (
                      <ErrorContainer>
                        <TextError>{errors.documentType}</TextError>
                      </ErrorContainer>
                    ) : (
                      ''
                    )}
                  </Block>
                  <Block m="0.5rem 0rem">
                    <Input
                      type="text"
                      value={values.documentNumber}
                      onChange={(e) => {
                        if (values.documentType === '3') {
                          handleChange('documentNumber')(e)
                          handleValidateDocumentNumber(e)
                        } else if (
                          values.documentType === '1' ||
                          values.documentType === '2' ||
                          values.documentType === '4'
                        ) {
                          const isNumber = e.target.value.match(/^\d+$/)
                          if (isNumber || e.target.value === '') {
                            handleChange('documentNumber')(e)
                            handleValidateDocumentNumber(e)
                          }
                        }
                      }}
                      name="documentNumber"
                      label={t('global.register.documentNumber')}
                      error={errors.documentNumber && touched.documentNumber ? true : false}
                      onBlur={() => {
                        handleBlur('documentNumber')
                      }}
                    />
                    {errors.documentNumber ? (
                      <ErrorContainer>
                        <TextError>{errors.documentNumber}</TextError>
                      </ErrorContainer>
                    ) : (
                      ''
                    )}
                  </Block>
                </>
              )}

              {step === 2 && (
                <>
                  <Block m="0.5rem">
                    <Input
                      type="text"
                      value={values.firstName}
                      onChange={handleChange('firstName')}
                      name="firstName"
                      label={t('global.register.userNameInputPlaceholder')}
                      error={errors.firstName && touched.firstName ? true : false}
                      disabled={true}
                    />
                    {errors.firstName && touched.firstName ? <div>{errors.firstName}</div> : ''}
                  </Block>
                  <Block m="0.5rem">
                    <Input
                      type="text"
                      name="lastName"
                      value={values.lastName}
                      onChange={handleChange('lastName')}
                      label={t('global.register.lastNameInputPlaceholder')}
                      error={errors.lastName && touched.lastName ? true : false}
                      disabled={true}
                    />
                    {errors.lastName && touched.lastName ? <div>{errors.lastName}</div> : ''}
                  </Block>

                  <Block m="0.5rem">
                    <DatePicker
                      useISO8601
                      label={t('global.register.birthday')}
                      format="DD/MM/YYYY"
                      value={values.birthDate}
                      clearDate={new Date(values.birthDate) > new Date()}
                      onChange={(e) => {
                        if (new Date(e) > new Date()) {
                          showDateAlert()
                        }
                        handleChange('birthDate')(e)
                        validateDate(e)
                      }}
                    />
                    {errors.birthDate && touched.birthDate ? (
                      <ErrorContainer>
                        <TextError>{errors.birthDate}</TextError>
                      </ErrorContainer>
                    ) : (
                      ''
                    )}
                  </Block>
                </>
              )}

              <Row margin="15px 0px 2px 0px">
                {step > 0 && step < 2 && (
                  <Block m="0.5rem">
                    <Button
                      onClick={() => {
                        if (step > 0) setStep(step - 1)
                      }}
                      style={{
                        color: '#000',
                        padding: '1rem 2.3rem',
                        backgroundColor: '#fff',
                        border: '1px solid #000',
                        borderRadius: '0.5rem',
                      }}
                      type="button"
                    >
                      {t('global.register.backButton')}
                    </Button>
                  </Block>
                )}
                {step === 0 && (
                  <Block m="0.5rem">
                    <Button
                      style={{
                        padding: '1rem 2rem',
                      }}
                      disabled={
                        !validPassword ||
                        values.password === '' ||
                        values.confirmPassword === '' ||
                        (values.password !== '' &&
                          values.confirmPassword !== '' &&
                          values.password !== values.confirmPassword)
                      }
                      onClick={() => {
                        if (isAdminUser) {
                          setStep(2)
                        } else {
                          setStep(step + 1)
                        }
                      }}
                      type="button"
                    >
                      {t('global.common.nextButtonText')}
                    </Button>
                  </Block>
                )}
                {step === 1 && !isAdminUser && (
                  <Block m="0.5rem">
                    <Button
                      style={{
                        padding: '1rem 2rem',
                      }}
                      disabled={
                        errors.documentType || errors.documentNumber
                          ? true
                          : false || values.documentType === '' || values.documentNumber === '' || !documentValid
                      }
                      onClick={() => {
                        setStep(step + 1)
                      }}
                      type="button"
                    >
                      {t('global.common.nextButtonText')}
                    </Button>
                  </Block>
                )}
                {step === 2 && (
                  <Block m="0.5rem">
                    <Button
                      style={{
                        padding: '1rem 2rem',
                      }}
                      disabled={new Date(values.birthDate) > new Date() || !dateValid}
                      onClick={() => {
                        handleSubmit()
                        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                        step !== 2 ? setStep(step + 1) : null
                      }}
                      type="button"
                    >
                      {step === 2 ? t('global.register.activateAccountButtonText') : 'Siguiente'}
                    </Button>
                  </Block>
                )}
              </Row>
              <Row>
                <HaveAccount href="/login">{t('global.register.alreadyHaveAccount')}</HaveAccount>
              </Row>
            </Block>
          </Form>
        )}
      </Formik>
      <AlertComponent
        message="La fecha de nacimiento no puede ser mayor a la fecha actual"
        severity="warning"
        open={showAlertDate}
      />
    </Container>
  )
}

export default RegisterForm
