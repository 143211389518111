import axios from 'axios'

interface ICompanyInfoResponse {
  status: string
  data: IIntegration[]
}

export interface IIntegration {
  id: number
  name: string
  url: string
}

const getIntegrations = async () => {
  const res: ICompanyInfoResponse = await axios
    .get(`/companies/integrations/options`)
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      return { ok: false, message: error.message }
    })
  return res
}

export default getIntegrations
