import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`

export const CardTitle = styled.h3`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.00735em;
  margin: 0;
`

export const WrapperContent = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`

export const BetesaScores = styled.div`
  display: flex;
  width: 60%;
  flex-direction: column;
  gap: 20px;
  position: sticky;
  top: 6.5rem;
`

export const CardRowTableSticky = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 45px;
  position: sticky;
  top: 6.5rem;
`

export const InfoWrapper = styled.div`
  display: flex;
  width: 40%;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 1.7rem;
  @media (max-width: 1024px) {
    margin-bottom: 1.7rem;
  }
`

export const CardRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 80px;
`

export const CardRowTable = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 45px;
`

export const CardTitleWithColor = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  font-size: 15px;
  background: #e6eef3;
  color: #212b36;
  width: 100%;
  border-radius: 10px 10px 0 0;
  padding: 8px 0;
`

export const CardInfoWithTable = styled.div`
  display: flex;
  background-color: white;
  flex-direction: column;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  width: 100%;
`

export const LoaderContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`
