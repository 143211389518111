import axios from 'lib/axios'

interface IResponse  {
    map(arg0: (item: any) => Promise<{ name: any; type: any; credits: any; tests_used: any; tests_available: string | number; start_date: string; expiration_date: string; state: string }>): any
    id: number,
    state: boolean,
    testsUsed: number,
    startDate: string,
    expirationDate: string,
    company_id: number,
    subscription_id: number,
    name: string,
    credits: number,
}

type IRes = {
    data: IResponse,
    total_pages: number
    status: 'success' | 'error',
}

// service to create inventory company
export default async function index ( companyId: number, page:number, limit:number) {
    let apiUrl = `${process.env.REACT_APP_MAIN_API}/subscription_company/company/${companyId}`

    if (page && limit) {
        apiUrl = `${apiUrl}?page=${page || 1}&limit=${limit || 10}`
    }

    try {
        const response: IRes = await axios.get(apiUrl).then((res) => {
            return res.data
        }).catch((err) => {
            console.error('error on get inventory by company', err)
        })
        return response
    }
    catch (error) {
        console.error('error on get inventory by company', error)
    }
}
