import axios from 'axios'

interface ICompanyInfoResponse {
  status: string
  data: ITestCount
}

export interface ITestCount {
  testCount: {
    draftTests: number
    sentTests: number
    totalTests: number
    categories: { Betesa: number; Skills: number }
  }
}

const getTestsForCompany = async (processId?: number) => {
  const url = processId ? `/company/process/test?process_id=${processId}` : `/company/process/test`

  const res: ICompanyInfoResponse = await axios
    .get(url)
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      return { ok: false, message: error.message }
    })
  return res
}

export default getTestsForCompany
